//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import { mapMutations } from 'vuex'
  import { miceService } from '@/service/miceService'
  export default {
    directives: {
      scroll: {
        bind: function(el, binding) {
          window.addEventListener('scroll', () => {
            if ((document.body.scrollTop && el.clientHeight && document.body.scrollTop + window.innerHeight >= document.body.clientHeight)) {
              var fn = binding.value
              fn()
            }
          })
        }
      }
    },
    data() {
      return {
        params: {//参数
          channel: localStorage.getItem('channel') || sessionStorage.getItem('channel') || 'h5',
          cityId: '',
          hospitalId: '',
          PageIndex: 0,
          PageSize: 20,
          keyword: '',
          distance:this.$store.state.ishop.ishopParam.distance,
          maxPriceAvg:this.$store.state.ishop.ishopParam.maxPriceAvg,
          minPriceAvg:this.$store.state.ishop.ishopParam.minPriceAvg,
        },
        shopList: [],//商户列表
        cartShops: [],//存放勾选商户
        oldKeyword:'',
      }
    },
    // props: ['sessionShops'],
    created(){
      /*
        console.log(this.sessionShops)
        this.Bus.$emit('myCartShops',this.sessionShops)
        let res = [this.shops[0]]
        for(let j=0,length = this.shops.length;j<length;j++){
          let isRepeat = false
          for(let k = 0,length = res.length;k<length;k++){
            if(res[k].shopId==this.shops[j].shopId){
              isRepeat = true
              break;
            }
          }
          if(!isRepeat){
            res.push(this.shops[j])
          }
        }
        this.shops = res
      */
    },
    computed: {
      isDisable: function() {
        return this.$store.state.ishop.isDisable
      }
      /*
        sessionShops: function () {
            this.Bus.$emit('myCartShops',this.sessionShops)
        }
      */
    },
    mounted() {
      this.Bus.$on('keywordSearchList',(keyword)=>{
        this.params.keyword = keyword
        this.params.PageIndex = 1
        this.params.PageSize = 20
        // this.params.keyword = keyword
        this.queryShops(false)
      })
      this.params.cityId = sessionStorage.getItem('pingRegionId')
      this.params.hospitalId = sessionStorage.getItem('hospitalId')
      
      this.Bus.$on('selectCartShops', res => {
        this.cartShops = res
//      console.log(this.cartShops)
      })
      /*
        var cached = JSON.parse(sessionStorage.getItem("cart"));
        this.cartShops = cached.shops
        this.cartShops.unshift.apply(this.cartShops, this.cart.shops)
      */
    },
    methods: {
      ...mapMutations([
          'changeisDisable'
      ]),
      loadMore: function() {
        this.params.PageIndex++
        this.queryShops(true)
      },
      setRecom:function(item){
        if(item.checked){
          return true;
        }else {
          return false;
        }
      },
      checkRadio(item) {
        var flag=false;
        var cartShopsIndex='';
        // console.log(this.cartShops)
        for (var i = 0, length = this.cartShops.length; i < length; i++) {//循环勾选商户列表与当前点击商户比较存在记录列表中的index
          if (item.shopId == this.cartShops[i].shopId) {
            flag=true;
            cartShopsIndex=i;
            break;
          }
        }
        if(item.recommendId=='00000000-0000-0000-0000-000000000000') {//处理recommendId初始状态
          item.recommendId = null
        }
        let isActiveSelect = item.recommendId?false:true//是否为首次选择的餐厅
        //删除已选餐厅功能<==>判断是否为首次选择
        if(flag&&item.checked&&isActiveSelect){
          item.checked=false;
          if(item.recommendId){
              item.rowId = item.recommendId;
              /*保存之后的删除==>
                item.checked = false;
                this.removeMyShop(item,cartShopsIndex)
              */
          }

          this.cartShops.splice(cartShopsIndex, 1);
        }
        
        if(!flag&&!item.checked){//不存在勾选列表且未被选中
          if(this.cartShops.length >= this.$store.state.ishop.maxQty5){//勾选列表内容是否超出规则限制？messageBox:push
            this.messageBox({
              message: `您已达到选择餐厅上限`,
              confirmButtonText: '确认'
            })
          }else {
            item.checked=true;
            item.recommendType = 1;
            this.cartShops.push(item)
          }
        }
        // sessionStorage.setItem("cart", JSON.stringify(this.cart))
        this.Bus.$emit("myCartList", this.cartShops)//将勾选列表暴露出去
      },
      removeMyShop: async function(item,cartShopsIndex) {
        let input = {
          rowId:  item.rowId
        }
        const res = await miceService.removeMyShop(input)

      },
      changeStuted(item) {//打开商户详情页
        let stuted = true
        this.Bus.$emit('changeStuted', stuted);
        this.Bus.$emit('shopItem', item)
      },
      queryShops: async function(type) {//获取商户列表
        if(this.isDisable){
          // alert(this.isDisable)
          return;
        }
        if(!type){
          this.shopList = []
        }
        let params = this.params
        const res = await miceService.queryShops(params)
        if (res.data.length==0 && type) {
          
          this.params.PageSize = 0
          this.toast({
            message: '已到商户列表结尾',
            duration: 800,
            className: 'ttt'
          });
        }else if(res.data.length==0 && !type){
          this.params.PageSize = 0
          this.toast({
            message: '找不到相关内容',
            duration: 800,
            className: 'ttt'
          });
        }else{
          if(this.params.keyword==this.oldKeyword){
            this.shopList=this.shopList.concat(res.data)
          }else {
            this.shopList=res.data
          }
        }
        /*
          for(let x=0,length=this.shopList.length;x<length;x++){
            for(let z=0,length=this.sessionShops.length;z<length;z++){
              if(this.shopList[x].shopId==this.sessionShops[z].shopId){
                this.shopList[x].checked=true
              }
            }
          }
        */
        this.oldKeyword=this.params.keyword;
      },
    }
  }
